// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Register_authBlock__form__zs9Tf .authBlock__form-group {
  display: grid;
  justify-content: stretch;
}
.Register_authBlock__form__zs9Tf .authBlock__form-group input.Register__invalid__vRbIq {
  border: 2px solid #e11f1f;
}
.Register_authBlock__form__zs9Tf .authBlock__form-group span {
  color: #e11f1f;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.Register_politics__ANKzq {
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: start;
}
@media (max-width: 600px) {
  .Register_politics__ANKzq {
    margin-top: 10px;
  }
}
.Register_politics__ANKzq span {
  font-size: 16px;
  line-height: 1.2;
}
@media (max-width: 600px) {
  .Register_politics__ANKzq span {
    font-size: 14px;
  }
}
.Register_politics__ANKzq span a {
  text-decoration: underline;
  margin-left: 3px;
}
.Register_politics__ANKzq .Register_checkbox__Diax5 {
  color: var(--main-color) !important;
}
.Register_politics__ANKzq .Register_checkbox_error__fqzPI {
  grid-column: 1/3;
  color: #e11f1f;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.Register_button__7pAGR {
  background-color: var(--main-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Register.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,wBAAA;AAAJ;AAGM;EACE,yBAAA;AADR;AAKI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAHN;;AAQA;EACE,aAAA;EACA,+BAAA;EACA,oBAAA;AALF;AAOE;EALF;IAMI,gBAAA;EAJF;AACF;AAME;EACE,eAAA;EACA,gBAAA;AAJJ;AAMI;EAJF;IAKI,eAAA;EAHJ;AACF;AAKI;EACE,0BAAA;EACA,gBAAA;AAHN;AAOE;EACE,mCAAA;AALJ;AAQE;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AANJ;;AAUA;EACE,mCAAA;AAPF","sourcesContent":[".authBlock__form {\n  :global(.authBlock__form-group) {\n    display: grid;\n    justify-content: stretch;\n\n    input {\n      &._invalid {\n        border: 2px solid #e11f1f;\n      }\n    }\n\n    span {\n      color: #e11f1f;\n      font-size: 18px;\n      font-weight: 500;\n      margin-bottom: 10px;\n    }\n  }\n}\n\n.politics {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  align-content: start;\n\n  @media (max-width: 600px) {\n    margin-top: 10px; \n  }\n\n  span {\n    font-size: 16px;\n    line-height: 1.2;\n\n    @media (max-width: 600px) {\n      font-size: 14px;\n    }\n\n    a {\n      text-decoration: underline;\n      margin-left: 3px;\n    }\n  }\n\n  .checkbox {\n    color: var(--main-color) !important;\n  }\n\n  .checkbox_error {\n    grid-column: 1/3;\n    color: #e11f1f;\n    font-size: 18px;\n    font-weight: 500;\n    margin-bottom: 10px;\n  }\n}\n\n.button {\n  background-color: var(--main-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authBlock__form": `Register_authBlock__form__zs9Tf`,
	"_invalid": `Register__invalid__vRbIq`,
	"politics": `Register_politics__ANKzq`,
	"checkbox": `Register_checkbox__Diax5`,
	"checkbox_error": `Register_checkbox_error__fqzPI`,
	"button": `Register_button__7pAGR`
};
export default ___CSS_LOADER_EXPORT___;
